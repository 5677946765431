import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { BreakPointService } from './break-point.service';

export const IS_HANDSET = new InjectionToken<Observable<boolean>>(
  'isHandset as stream',
  {
    providedIn: 'root',
    factory: () => inject(BreakPointService).isHandset$,
  },
);
